import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../components/Layout';

const RendezvenyekPage = () => (
  <Layout page="rendezvenyek">
    <article id="main">
      <header>
        <h2>EVENTS</h2>
        <p>WINE TASTINGS, WEDDINGS, FAMILY EVENTS, ACCOMMODATION</p>
      </header>
    </article>

    <section id="one" className="wrapper alt style2">
      <section id="tasting" className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/rendezvenyek/tasting.jpg"
            alt="Wine tasting"
          />
        </div>
        <div className="content">
          <h2>WINE TASTING</h2>
          <p>
            Our winery is located in one of Eger’s traditional cellar rows, in
            Kőlyuk, where there have been rows of family cellars carved into the
            wine region’s typical rhyolite tuff for centuries. We have also
            expanded the family cellar in the last few decades, and it now
            boasts the largest cask capacity in Eger. Our bottle ageing cellars
            are also located here and are a stunning sight. We have opened our
            cellars to the public, so that visitors can learn at close hand
            about the work that goes on here.
          </p>
        </div>
      </section>

      <section className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/rendezvenyek/tasting_2.jpg"
            alt="Wine tasting"
          />
        </div>
        <div className="content">
          <p>
            The winery also has a hospitality centre boasting many facilities.
            This not only evokes a classic wine cellar atmosphere but also helps
            visitors discover the wine region and our vineyards and follow the
            path of the grapes from harvesting to bottling. The intimate wine
            tasting corners provide excellent spots for individual wine
            tastings, while the imposing tasting room is also ideal for friends,
            family and corporate events as well as exclusive receptions and
            weddings of up to 100 people.
          </p>
        </div>
      </section>

      <section id="wine-tasting" className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/rendezvenyek/personal.jpg"
            alt="WINE TASTING WITH CELLAR VISIT FOR INDIVIDUAL VISITORS"
          />
        </div>
        <div className="content">
          <h2>WINE TASTING WITH CELLAR VISIT FOR INDIVIDUAL VISITORS</h2>
          <p>
            During opening hours, you can buy and taste wine in the winery
            without prior arrangement. We can also arrange a cellar visit and a
            professional tour guide by prior arrangement, but you will need to
            book a specific date and time.
          </p>
          <p>
            <b>Opening hours</b>
            <br style={{ display: 'block' }} />
            Monday-Friday: 8 am to 4 pm, Saturday: 9 am to 6 pm
          </p>
          <p>
            <b>Registration and appointment arrangement:</b>
            <br style={{ display: 'block' }} />
            Email: borkostolo@juhaszvin.hu
            <br style={{ display: 'block' }} />
            Phone: +36 20 215 1608
          </p>
        </div>
      </section>

      <section id="tasting-group" className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/rendezvenyek/winery.jpeg"
            alt="WINE TASTING WITH CELLAR VISIT FOR GROUPS"
          />
        </div>
        <div className="content">
          <h2>WINE TASTING WITH CELLAR VISIT FOR GROUPS</h2>
          <p>
            Primarily by prior arrangement, we also organise expert wine
            tastings for groups, accompanied by a cellar visit, any day of the
            week. We can also provide snacks with these, for example freshly
            baked scones or a mixed cheese and charcuterie plate. Hot meals are
            also available on request.
          </p>
          <p>
            <b>Registration and appointment arrangement: </b>
            <br style={{ display: 'block' }} />
            Email: borkostolo@juhaszvin.hu
            <br style={{ display: 'block' }} />
            Phone: +36 20 215 1608
          </p>
        </div>
      </section>

      <section id="tasting-offsite" className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/rendezvenyek/external.jpeg"
            alt="OFF-SITE WINE TASTING"
          />
        </div>
        <div className="content">
          <h2>OFF-SITE WINE TASTING</h2>
          <p>
            By prior arrangement, we hold tastings of our wines nationwide. We
            can also provide suitable wines to pair with dishes at wine dinners
            together with professional presentation.
          </p>
          <p>
            <b>Request for quotation, appointment arrangement:</b>
            <br style={{ display: 'block' }} />
            Email: borkostolo@juhaszvin.hu
            <br style={{ display: 'block' }} />
            Phone: +36 20 215 1608
          </p>
          <p>Please contact us to discuss the best time for your tasting!</p>
        </div>
      </section>

      <section id="wedding" className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/rendezvenyek/location.jpg"
            alt="WEDDING VENUE"
          />
        </div>
        <div className="content">
          <h2>WEDDING VENUE</h2>
          <p>
            Our traditional family winery was expanded with a new
            multifunctional tasting and function room in 2012. Our aim was to
            uniquely combine a peaceful, pleasant location in Eger equipped to
            modern European standards, thus creating a suitable venue for a
            dream wedding. Our Eger complex provides a suitable environment for
            classic and modern weddings. Our goal is to make this beautiful
            occasion truly unforgettable and perfect from the very first moment.
          </p>
          <p>
            Our function room will comfortably accommodate 100 people for
            celebrations.
          </p>
          <p>
            Our highly experienced team will not only organise your wedding on
            the big day itself, but we will also offer you, the Happy Couple and
            your family members, access to the venue two days beforehand.
          </p>
          <p>
            <ul>
              <li>
                We provide a lockable dressing room for the happy couple and
                family members, which is also suitable for storing gifts.
              </li>
              <li>
                There is free guarded(!) parking available at the venue
                throughout the event.
              </li>
              <li>
                The Juhász Brothers Winery did not forget the kids when they
                were building the hospitality centre. There is a modern,
                well-equipped, brand-new children’s playground to entertain the
                little ones. A qualified nanny is available to supervise them if
                required.
              </li>
            </ul>
          </p>
          <p>
            <b>
              Request for quotation, registration, appointment arrangement:{' '}
            </b>
            <br style={{ display: 'block' }} />
            Email: borkostolo@juhaszvin.hu
            <br style={{ display: 'block' }} />
            Phone: +36 20 215 1608
          </p>
        </div>
      </section>

      <section className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/rendezvenyek/ceremony.jpg"
            alt="OUR CEREMONY-LEADING PARTNER"
          />
        </div>
        <div className="content">
          <h2>
            OUR CEREMONY-LEADING PARTNER
            <br style={{ display: 'block' }} />
            DÖNIZ KOVÁCS – CEREMONY LEADER
          </h2>
          <p>
            The perfect wedding ceremony is as emotional and unique as love. As
            a ceremony leader, I can get to know the couple in advance and make
            the wedding more personal. Including their common stories in my
            speech is my vocation. I plan all ceremonies with passion and
            prepare for this vow-taking with the maximum experience. My goal is
            to ensure that when the couple thinks back to their wedding day
            years later, they can say it was truly all about them. Seeking the
            first, the most beautiful day of your life together, with confidence
            and realising it together, the day that you have dreamed of, when
            you wanted it, just like you imagined and with the words that you
            wanted.
          </p>
          <p>Confirmation of marriage vows</p>
          <p>
            Wedding anniversaries are important and special dates in a couple’s
            life. It is a lovely custom to surprise each other on this
            anniversary with an intimate dinner, a beautiful bouquet of flowers
            or a weekend away together. And it is even more special to confirm
            the vows you made at the time of your wedding. The confirmation of
            vows made on a key wedding anniversary symbolises your appreciation
            of your spouse and the deepening of this over the years you have
            spent together.
          </p>
          <p>
            Phone: + 36 20 980 5186
            <br style={{ display: 'block' }} />
            Email: kovacsdonizszertartasvezeto@gmail.com
            <br style={{ display: 'block' }} />
            <a
              href="https://www.instagram.com/szertartasvezeto_eger/"
              target="__blank"
            >
              Instagram
            </a>
            <span> </span>
            <a
              href="https://www.facebook.com/kovacsdonizszertartasvezeto/"
              target="__blank"
            >
              Facebook
            </a>
          </p>
        </div>
      </section>
    </section>
  </Layout>
);

export default RendezvenyekPage;
